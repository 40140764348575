<template>
  <div class="content">
    <h1>Trivvel Elements</h1>
    <div class="line"></div>

    <div class="group_title">Headings</div>
    <div class="group">
      <h1>Heading h1</h1>
      <h2>Heading h2</h2>
      <h3>Heading h3</h3>
      <h4>Heading h4</h4>
      <h5>Heading h5</h5>
      <h6>Heading h6</h6>
    </div>

    <div class="group_title">Buttons</div>
    <div class="group">
      <button type="submit" class="button">
        <inline-svg
          :src="require('../assets/icons/login.svg')"
        ></inline-svg>
        Log in
      </button>
      <button type="submit" class="button medium">
        <inline-svg
          :src="require('../assets/icons/login.svg')"
        ></inline-svg>
        Log in
      </button>
      <button type="submit" class="button small">
        <inline-svg
          :src="require('../assets/icons/login.svg')"
        ></inline-svg>
        Log in
      </button>
      <br />
      <br /><br />
      <button type="submit" class="button noimage">Log in</button>
      <button type="submit" class="button medium noimage">Log in</button>
      <button type="submit" class="button small noimage">Log in</button>
      <br /><br />
      <button type="submit" class="button noimage reverse">Log in</button>
      <button type="submit" class="button medium noimage reverse">Log in</button>
      <button type="submit" class="button small noimage reverse">Log in</button>
    </div>

    <div class="group_title">Paragraphs</div>
    <div class="group">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi et lectus sapien.
        Sed sollicitudin sollicitudin hendrerit. Sed leo augue, facilisis non consectetur
        viverra, dapibus a nisl. Nulla a purus tellus. Vivamus lectus arcu, auctor quis
        velit sit amet, gravida suscipit libero. Sed tempus elit sit amet nibh porta, et
        fringilla eros interdum. Etiam cursus est eget dictum ultrices.
      </p>
      <p class="medium">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi et lectus sapien.
        Sed sollicitudin sollicitudin hendrerit. Sed leo augue, facilisis non consectetur
        viverra, dapibus a nisl. Nulla a purus tellus. Vivamus lectus arcu, auctor quis
        velit sit amet, gravida suscipit libero. Sed tempus elit sit amet nibh porta, et
        fringilla eros interdum. Etiam cursus est eget dictum ultrices.
      </p>
      <p class="small">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi et lectus sapien.
        Sed sollicitudin sollicitudin hendrerit. Sed leo augue, facilisis non consectetur
        viverra, dapibus a nisl. Nulla a purus tellus. Vivamus lectus arcu, auctor quis
        velit sit amet, gravida suscipit libero. Sed tempus elit sit amet nibh porta, et
        fringilla eros interdum. Etiam cursus est eget dictum ultrices.
      </p>
    </div>

    <div class="group_title">Forms</div>
    <div class="group">
      <label>Name</label>
      <input type="text" value="Nathalie" placeholder="Name" />

      <label>Password</label>
      <input type="password" value="password" placeholder="password" />

      <label>Message</label>
      <textarea placeholder="Your message.."></textarea>

      <label>Select</label>
      <select name="select">
        <option>True</option>
        <option>False</option>
      </select>

      <label>
        <input type="checkbox" />
        I agree to the terms
      </label>

      <label class="radio">
        <input type="radio" name="question" />
        Yes
      </label>
      <label class="radio">
        <input type="radio" name="question" />
        No
      </label>
    </div>
  </div>
</template>
<style lang="sass">
#app
  display: block
  margin: 4rem

.content
  padding: 2rem
  text-align: left
  margin: 0

  .line
    border-bottom: 1px solid #ccc
    margin-bottom: 2rem

  .group_title
    font-weight: 400

  .group
    margin-top: 2rem
    border-radius: 2rem
    padding: 2rem
    width: 100%
    background-color: white
    margin-bottom: 4rem

  h1,h2,h3,h4,h5,h6
    padding: 1rem
</style>
